<template>
  <!-- 发运信息详情 -->
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <div>入库单基本信息</div>
    </el-row>
    <el-form label-width="102px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="入库单号：">{{ DetailsDatas.trayId }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="关联单号：">{{ DetailsDatas.orderId }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收货单号：">{{ DetailsDatas.receiptId }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库仓库：">{{ DetailsDatas.logicWarehouseName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库类型：">{{ getDepositLabel(DetailsDatas.depositType) }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库数量：">{{ dataObj.tatolPairs }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库时间：">{{ dataObj.inboundTime }}</el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <!-- <el-row>
      <div>入库单明细</div>
      <el-divider />
    </el-row>
    <el-table ref="multipleTable" class="mb-3" :data="ShippingInfoable" :header-cell-style="{background:'#fafafa'}" max-height="500px">
      <el-table-column
        prop="sku"
        label="SKU"
        align="center"
      />

      <el-table-column
        prop="upc"
        label="UPC"
        align="center"
      />
      <el-table-column
        prop="fnsku"
        label="FNSKU"
        align="center"
      />
      <el-table-column
        prop="style"
        label="Style"
        align="center"
      />

      <el-table-column
        prop="color"
        label="Color"
        align="center"
      />
      <el-table-column
        prop="size"
        label="Size"
        align="center"
      />

      <el-table-column
        prop="actualPairs"
        label="入库数量"
        align="center"
      />
    </el-table> -->
    <el-row class="grid-content bg-purple-dark">
      <div>入库记录</div>
    </el-row>
    <el-table ref="multipleTable" :summary-method="getTotals" show-summary class="mb-3" :data="ShippingInfoable" :header-cell-style="{background:'#fafafa'}" max-height="500px">
      <el-table-column
        type="index"
        :label="$t('page.No')"
        align="center"
      />
      <el-table-column
        prop="platformCode"
        label="平台"
        align="center"
      />
      <el-table-column
        prop="siteCode"
        label="站点"
        align="center"
      />
      <!-- <el-table-column
        prop="locationCode"
        label="库位"
        align="center"
      /> -->
      <el-table-column
        prop="trayCode"
        label="托盘"
        align="center"
      />
      <el-table-column
        prop="sku"
        label="SKU"
        align="center"
      />

      <el-table-column
        prop="upc"
        label="UPC"
        align="center"
      />
      <el-table-column
        prop="fnsku"
        label="FNSKU"
        align="center"
      />
      <el-table-column
        prop="style,color,size"
        :label="$t('page.Specifications')"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
        </template>
      </el-table-column>
      <el-table-column
        prop="actualQuantity"
        label="入库箱数"
        align="center"
      />
      <!-- <el-table-column
        prop="actualQuantity"
        label="应收数量"
        align="center"
      /> -->
      <el-table-column
        prop="actualPairs"
        label="入库数量"
        align="center"
      />
      <!-- <el-table-column
        prop="createByName"
        label="创建人"
        align="center"
      />
      <el-table-column
        prop="createTime"
        label="操作时间"
        align="center"
      /> -->
    </el-table>
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </div>

</template>

<script>
import { findTrayDetail } from '@/api/stockin'
import { Mixin } from '@/mixin/mixin.js'
export default {
  mixins: [Mixin],
  data() {
    return {
      DetailsDatas: {},
      dataObj: { tatolPairs: '', inboundTime: '' },
      ShippingInfoable: [],
      tatolQuantity: 0,
      tatolPairs: 0
    }
  },

  mounted() {
    const { trayData } = this.$route.query
    this.DetailsDatas = JSON.parse(trayData)
    this._findTrayDetail()
  },
  methods: {
    cancle() {
      this.$router.go(-1)
    },
    // 获取详情数据
    async _findTrayDetail() {
      const { trayId, tableSuffix } = this.$route.query
      const data = { trayId, tableSuffix }
      const { datas } = await findTrayDetail(data)
      const { tatolPairs, inboundTime } = datas
      this.dataObj = { tatolPairs, inboundTime }
      this.ShippingInfoable = datas.ttd
      this.tatolQuantity = datas.tatolQuantity
      this.tatolPairs = datas.tatolPairs
      // if (this.DetailsDatas.transportStatus) {
    },
    // 出库单总价显示
    getTotals(param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('page.total')
          return
        }
        if (column.property === 'actualQuantity') {
          sums[index] = this.tatolQuantity
        } else if (column.property === 'actualPairs') {
          sums[index] = this.tatolPairs
        } else {
          sums[index] = ''
        }
      })
      return sums
    }

  }
}
</script>
<style lang="scss" scope>
.float-right {
  display: flex;
  justify-content: flex-end;
}
</style>
